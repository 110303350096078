import { AnimatePresence } from 'framer-motion'
import { useCallback } from 'react'
import { useNav } from '../components/navigation-context'
import HomeScreen from './homeScreen'
import AnswerScreen from './answerScreen'
import InputScreen from './inputScreen'
import SuccessScreen from './successScreen'

export default function Manager() {
	const nav = useNav()

	const renderScreens = useCallback(navigation => {
		switch (navigation) {
			case 'answers':
				return <AnswerScreen key='answers' />
			case 'input':
				return <InputScreen key='input' />
			case 'success':
				return <SuccessScreen key='success' />
			default:
				return <HomeScreen key='home' />
		}
	}, [])

	return (
		<>
			<div
				className={`absolute top-4 z-50 flex w-full items-center justify-center
					transition-all duration-[1500ms] md:top-8
					${nav === 'success' ? 'text-white' : 'text-black'}
					${nav === 'answers' ? 'pointer-events-none opacity-0 md:opacity-100' : ''}`}>
				<span className='text-sm text-opacity-40'>
					Created with{' '}
					<a
						href='https://fasttracklaunch.com'
						target='_blank'
						rel='noopener noreferrer'
						className='font-bold text-inherit underline underline-offset-2
							transition-opacity duration-500 md:hover:opacity-60'>
						Fast Track Launch
					</a>
				</span>
			</div>
			<AnimatePresence mode='wait'>
				{renderScreens(nav)}
			</AnimatePresence>
		</>
	)
}
