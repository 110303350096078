import AnimatedLayout from '../components/animated-layout'
import Input from '../components/screens/input'

export default function InputScreen() {
	return (
		<AnimatedLayout>
			<Input />
		</AnimatedLayout>
	)
}
