/* eslint-disable no-unused-vars */
import { useRef } from 'react'
import { useInputData, useSetNav } from '../navigation-context'
import AnswersView from '../answers-view'

export default function Answers() {
	const input = useInputData()
	const { setNavigation, setInputData } = useSetNav()

	const btnsRef = useRef(null)
	const abortController = new AbortController()

	const handleStopStream = () => {
		abortController.abort()
		setInputData(null)
		setNavigation('input')
	}

	const toggleStreamStatus = () => {
		btnsRef.current?.classList?.toggle('hidden-pointer')
		document
			.querySelector('#stop-btn')
			?.classList?.toggle('hidden-pointer')
		document
			.querySelector('#answers')
			?.classList?.toggle('answer-full')
	}

	const handleSubmit = () => {
		setNavigation('success')
	}

	const handleResetStream = () => {
		setInputData(`${input} `)
		toggleStreamStatus()
	}

	return (
		<>
			<div
				id='answers'
				className='answer-full flex size-full flex-col items-start justify-center
					px-4 transition-opacity duration-500 md:items-center md:px-0'>
				<AnswersView
					inputData={input}
					abortSignal={abortController.signal}
					toggleStreamStatus={toggleStreamStatus}
				/>
			</div>
			<div
				id='stop-btn'
				className='absolute bottom-0 w-full bg-gradient-to-t from-indigo-100
					via-indigo-100/90 to-transparent p-4 pt-12 transition-opacity
					duration-500 md:bottom-8 md:w-fit md:bg-none'>
				<button
					type='button'
					id='stop-btn'
					onClick={handleStopStream}
					className='custom-font relative z-10 w-full whitespace-nowrap rounded-3xl
						bg-white px-12 py-6 text-center text-3xl font-semibold
						text-indigo-500 transition-colors duration-500 md:text-4xl
						md:hover:bg-indigo-500 md:hover:text-white'>
					Stop and go back
				</button>
			</div>
			<div
				ref={btnsRef}
				className='hidden-pointer absolute bottom-0 flex w-full flex-col
					bg-gradient-to-t from-indigo-100 via-indigo-100/90 to-transparent
					p-4 pt-12 transition-opacity duration-500 md:bottom-8 md:w-fit
					md:flex-row md:bg-none'>
				<button
					type='button'
					onClick={handleResetStream}
					className='custom-font relative z-10 w-full whitespace-nowrap rounded-3xl
						bg-white px-12 py-6 text-3xl font-semibold text-indigo-500
						transition-colors duration-500 md:w-fit md:text-4xl
						md:hover:bg-indigo-500 md:hover:text-white'>
					I&apos;m still bugged
				</button>
				<button
					type='button'
					onClick={handleSubmit}
					className='custom-font relative z-10 mt-2 w-full whitespace-nowrap
						rounded-3xl bg-indigo-500 px-12 py-6 text-3xl font-semibold
						text-white transition-colors duration-500 md:ml-4 md:mt-0
						md:w-fit md:text-4xl md:hover:bg-white md:hover:text-indigo-500'>
					I&apos;m calm, thanks
				</button>
			</div>
		</>
	)
}
