export default function Leaf({ className, id }) {
	return (
		<svg
			width='70'
			height='122'
			viewBox='0 0 70 122'
			fill='none'
			id={id}
			className={className}
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M34.9001 121.706C14.0414 109.64 0.0078125 87.0874 0.0078125 61.2572C0.0078125 35.4272 14.0412 12.8749 34.8995 0.808594C55.7582 12.8748 69.7918 35.4273 69.7918 61.2575C69.7918 87.0875 55.7585 109.64 34.9001 121.706Z'
			/>
		</svg>
	)
}
