import LogoIcon from './logo-icon'

export default function Logo({ className }) {
	return (
		<span className={`text-sm ${className}`}>
			to
			<LogoIcon className='dark-icon mx-2 inline size-5' />
			<strong>Kick The Anxiety</strong>
		</span>
	)
}
