import Answers from '../components/screens/answers'
import AnimatedLayout from '../components/animated-layout'

export default function AnswerScreen() {
	return (
		<AnimatedLayout className='items-start bg-indigo-100/60'>
			<Answers />
		</AnimatedLayout>
	)
}
