import {
	createContext,
	useContext,
	useMemo,
	useState
} from 'react'

const NavContext = createContext(null)
const InputDataContext = createContext(null)
const SetNavDataContext = createContext(null)

export default function NavigationContextProvider({ children }) {
	const [navigation, setNavigation] = useState(null)
	const [inputData, setInputData] = useState(null)

	const setters = useMemo(
		() => ({
			setNavigation,
			setInputData
		}),
		[setNavigation, setInputData]
	)

	return (
		<SetNavDataContext.Provider value={setters}>
			<NavContext.Provider value={navigation}>
				<InputDataContext.Provider value={inputData}>
					{children}
				</InputDataContext.Provider>
			</NavContext.Provider>
		</SetNavDataContext.Provider>
	)
}

export function useNav() {
	return useContext(NavContext)
}

export function useSetNav() {
	return useContext(SetNavDataContext)
}

export function useInputData() {
	return useContext(InputDataContext)
}
