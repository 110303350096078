import { animate } from 'framer-motion'
import { useEffect, useRef } from 'react'
import Leaf from '../icons/leaf'
import { useSetNav } from '../navigation-context'

export default function Home() {
	const containerRef = useRef(null)
	const { setNavigation } = useSetNav()

	useEffect(() => {
		const { current: container } = containerRef

		if (container) {
			const transition = {
				duration: 0.6,
				delay: 0.6,
				bounce: 0
			}

			animate(
				container.querySelector('svg:last-child'),
				{
					x: -35,
					y: 12,
					rotate: -40
				},
				transition
			)
			animate(
				container.querySelector('svg:first-child'),
				{
					x: 35,
					y: 12,
					rotate: 40
				},
				transition
			)
		}

		setTimeout(() => {
			setNavigation('input')
		}, 1200)
	}, [setNavigation])

	return (
		<div
			className='absolute left-0 top-0 z-10 flex size-full items-center
				justify-center bg-white'>
			<div className='relative' ref={containerRef}>
				<Leaf className='relative z-30 h-28 w-16 fill-indigo-500' />
				<Leaf className='absolute left-0 top-0 z-20 h-28 w-16 fill-indigo-400' />
				<Leaf className='absolute left-0 top-0 z-10 h-28 w-16 fill-indigo-300' />
			</div>
		</div>
	)
}
