export default function LogoIcon({ className = 'dark-icon' }) {
	return (
		<svg
			width='184'
			height='146'
			viewBox='0 0 184 146'
			fill='none'
			className={className}
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				id='left-leaf'
				d='M91.9635 120.831C98.1815 97.5496 92.1579 71.6784 73.8927 53.4132C55.628 35.1485 29.7578 29.1247 6.47648 35.3419C0.258453 58.6238 6.28204 84.4949 24.5473 102.76C42.8119 121.025 68.6821 127.049 91.9635 120.831Z'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				id='center-leaf'
				d='M91.0899 120.898C111.949 108.832 125.983 86.2795 125.983 60.4491C125.983 34.6188 111.949 12.0661 91.0899 0C70.231 12.0661 56.1973 34.6188 56.1973 60.4491C56.1973 86.2795 70.231 108.832 91.0899 120.898Z'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				id='right-leaf'
				d='M92.0367 121.327C85.8195 98.0456 91.8432 72.1753 110.108 53.9106C128.373 35.646 154.242 29.6222 177.524 35.8391C183.741 59.1205 177.717 84.9908 159.452 103.256C141.188 121.52 115.318 127.544 92.0367 121.327Z'
			/>
		</svg>
	)
}
