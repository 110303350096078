import { useRef } from 'react'
import { useSetNav } from '../navigation-context'
import Logo from '../icons/logo'

export default function Input() {
	const { setInputData, setNavigation } = useSetNav()

	const btnRef = useRef(null)
	const textareaRef = useRef(null)

	const handleChange = event => {
		const { target } = event
		const { current: btn } = btnRef
		const { value, style } = target

		style.height = '1px'
		style.height = `${target.scrollHeight}px`

		if (btn) {
			btn.disabled = !value
			btn.classList.toggle('opacity-0', !value)
		}
	}

	const handleClick = () => {
		setInputData(textareaRef?.current?.value || '')
		setNavigation('answers')
	}

	return (
		<>
			<div className='flex flex-col items-center justify-center'>
				<textarea
					ref={textareaRef}
					onChange={handleChange}
					className='scrollbar-hide custom-font resize-none appearance-none
						text-center text-4xl font-semibold leading-[42px] outline-none
						md:text-6xl md:leading-[72px]'
					placeholder={`Write down what's\nbugging you`}
				/>
				<Logo className='mt-8' />
			</div>
			<button
				ref={btnRef}
				onClick={handleClick}
				type='button'
				className='custom-font absolute bottom-4 w-[calc(100%-32px)]
					whitespace-nowrap rounded-3xl bg-indigo-500 px-12 py-6 text-3xl
					font-semibold text-white opacity-0 transition-all duration-500
					md:bottom-8 md:w-60 md:text-4xl md:hover:bg-indigo-100/60
					md:hover:text-indigo-500'>
				Let&apos;s Go
			</button>
		</>
	)
}
