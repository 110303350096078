import { motion } from 'framer-motion'

export default function AnimatedLayout({
	children,
	className = 'items-center'
}) {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{
				duration: 0.75
			}}
			className={`flex size-full justify-center ${className}`}>
			{children}
		</motion.div>
	)
}
