import AnimatedLayout from '../components/animated-layout'
import { useSetNav } from '../components/navigation-context'
import Leaf from '../components/icons/leaf'

export default function SuccessScreen() {
	const { setNavigation } = useSetNav()

	return (
		<AnimatedLayout>
			<div className='flex size-full flex-col items-center justify-center bg-indigo-500'>
				<h1
					className='custom-font text-center text-4xl font-semibold text-white
						md:text-6xl'>
					I was glad
					<br />
					to help you{' '}
					<span className='light-icon relative ml-1 inline-block md:ml-4'>
						<Leaf
							className='relative z-10 h-5 w-3 fill-[#BCC0FF] md:h-10 md:w-6'
							id='left-leaf'
						/>
						<Leaf className='absolute left-0 top-0 z-20 h-5 w-3 fill-[#9BA0FF] md:h-10 md:w-6' />
						<Leaf
							className='absolute left-0 top-0 z-30 h-5 w-3 fill-white md:h-10 md:w-6'
							id='right-leaf'
						/>
					</span>
				</h1>
			</div>
			<button
				onClick={() => {
					setNavigation('input')
				}}
				type='button'
				className='custom-font absolute bottom-4 w-[calc(100%-32px)]
					whitespace-nowrap rounded-3xl bg-[#9BA0FF] px-12 py-6 text-3xl
					font-semibold text-white transition-colors duration-500
					md:bottom-8 md:w-fit md:text-4xl md:hover:bg-white
					md:hover:text-indigo-500'>
				One more disrupture
			</button>
		</AnimatedLayout>
	)
}
