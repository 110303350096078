import NavigationContextProvider from './components/navigation-context'
import Manager from './pages/manager'

export default function App() {
	return (
		<NavigationContextProvider>
			<main className='relative flex h-dvh w-screen overflow-hidden md:h-screen'>
				<Manager />
			</main>
		</NavigationContextProvider>
	)
}
