import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'

import { SpeedInsights } from '@vercel/speed-insights/react'
import { Analytics } from '@vercel/analytics/react'
import App from './app'

ReactDOM.createRoot(document.getElementById('root')).render(
	<>
		<App />
		<SpeedInsights />
		<Analytics />
	</>
)
