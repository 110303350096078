import { motion } from 'framer-motion'
import { useEffect, useState, useCallback } from 'react'
import chatSystemMessage from '../config'

const parseText = text => {
	const elements = text.split('/')

	return elements.map((element, index) => {
		const delay = index * 0.5

		return {
			text: element,
			delay
		}
	})
}

export default function AnswersView({
	inputData,
	abortSignal,
	toggleStreamStatus
}) {
	const [messages, setMessages] = useState('')

	const fetchData = useCallback(
		async (text, signal, toggleStatus) => {
			try {
				const req = await fetch(
					'https://front.figmasets.ru/api/proxy',
					{
						method: 'POST',
						headers: {
							'Content-type': 'application/json'
						},
						body: JSON.stringify({
							model: 'gpt-4o-2024-05-13',
							stream: false,
							messages: [
								{
									role: 'system',
									content: chatSystemMessage
								},
								{ role: 'user', content: text }
							]
						}),
						signal
					}
				)

				const res = await req.json()
				setMessages(res.choices[0].message.content)
			} catch {
				/* empty */
			}
			toggleStatus()
		},
		[setMessages]
	)

	useEffect(() => {
		setMessages('')
		fetchData(inputData, abortSignal, toggleStreamStatus)
	}, [inputData, abortSignal, toggleStreamStatus, fetchData])

	return (
		<div
			className='flex size-full flex-col gap-2 md:size-fit md:flex-row
				md:items-start md:justify-center'>
			{messages && (
				<div
					className='scrollbar-hide flex size-full flex-col gap-2 overflow-scroll
						md:w-2/3 md:flex-row md:flex-wrap md:items-stretch
						md:justify-start'>
					{parseText(messages)?.map(({ text, delay }, index) => (
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 0.5, delay }}
							className='flex flex-row flex-wrap gap-x-1 rounded-2xl bg-white p-4
								text-black/70 first:mt-4 last:mb-52 md:w-[calc(50%-0.25rem)]
								md:first:mt-0 md:last:mb-0'
							key={delay}>
							<span className='text-lg'>
								<strong>
									{String.fromCharCode(65 + index)}.{' '}
								</strong>
								{text}
							</span>
						</motion.div>
					))}
				</div>
			)}
		</div>
	)
}
