import AnimatedLayout from '../components/animated-layout'
import Home from '../components/screens/home'

export default function HomeScreen() {
	return (
		<AnimatedLayout>
			<Home />
		</AnimatedLayout>
	)
}
